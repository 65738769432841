import { useParams } from "react-router-dom";
import SubjEnglish from "pages/subjenglish/manage/SubjEnglish";

// using Proxy Page to remount when route to same route location but different param
function SubjEnglishProxy() {
  const { mode, subjenglishId } = useParams();
  return <SubjEnglish key={`${mode}/${subjenglishId}`} />;
}

export default SubjEnglishProxy;

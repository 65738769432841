import PropTypes from "prop-types";

import Card from "@mui/material/Card";

import MDBox from "components/atoms/MDBox";
import MDTypography from "components/atoms/MDTypography";

function EnglishQue({ Que }) {
  return (
    <MDBox>
      <MDBox lineHeight={0}>
        <MDTypography variant="h5">Question #1</MDTypography>
      </MDBox>
      <MDBox mt={3}>
        <Card>
          <MDBox
            component="img"
            src={Que}
            alt={""}
            borderRadius="lg"
            shadow="md"
            width="100%"
            height="100%"
            position="relative"
            zIndex={1}
          />
        </Card>
      </MDBox>
    </MDBox>
  );
}

EnglishQue.propTypes = {
  //mainForm: PropTypes.object.isRequired,
  //mainFormField: PropTypes.object.isRequired,
  modeDisabled: PropTypes.bool,
  dispatchMainError: PropTypes.func,
};

export default EnglishQue;

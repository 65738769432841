import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";

import MDBox from "components/atoms/MDBox";
import MDButton from "components/atoms/MDButton";

import ErrorSnackbar from "components/molecules/Notifications/ErrorSnackbar";
import SuccessSnackbar from "components/molecules/Notifications/SuccessSnackbar";

import DashboardLayout from "components/templates/DashboardLayout";
import Topnav from "components/organisms/Topnav";
import Footer from "components/organisms/Footer";
import PermissionDenied from "components/organisms/PermissionDenied";
import InvalidOperation from "components/organisms/InvalidOperation";
import IncompleteOperation from "components/organisms/IncompleteOperation";
import SubmissionProgress from "components/organisms/SubmissionProgress";

// SubjEnglish page components
import EnglishQue from "pages/subjenglish/manage/components/EnglishQue";
import EnglishParticulars from "pages/subjenglish/manage/components/EnglishParticulars";
import { useEnglishManager } from "pages/subjenglish/hooks/useEnglishManager";
import { Formik, Form } from "formik";
import form from "pages/subjenglish/manage/schemas/form";
import { AllowedTo } from "react-abac";
//import { Permission } from "models/abac";

import englishPassage from "assets/images/gate/english-passage.png";
import englishQ1 from "assets/images/gate/english-q1.png";
import englishQ1ans from "assets/images/gate/english-q1-ans.png";
import englishQ2 from "assets/images/gate/english-q2.png";
import englishQ2ans from "assets/images/gate/english-q2-ans.png";
import englishQ3 from "assets/images/gate/english-q3.png";
import englishQ3ans from "assets/images/gate/english-q3-ans.png";
import englishQ4 from "assets/images/gate/english-q4.png";
import englishQ4ans from "assets/images/gate/english-q4-ans.png";
import englishQ5 from "assets/images/gate/english-q5.png";
import englishQ5ans from "assets/images/gate/english-q5-ans.png";

function getSteps() {
  return ["Q1", "Q2", "Q3", "Q4", "Q5"];
}

function getStepContent(stepIndex, modeFieldDisabled) {
  switch (stepIndex) {
    case 0:
      return (
        <EnglishParticulars
          Que={englishQ1}
          Ans={englishQ1ans}
          modeDisabled={modeFieldDisabled}
        />
      );
    case 1:
      return (
        <EnglishParticulars
          Que={englishQ2}
          Ans={englishQ2ans}
          modeDisabled={modeFieldDisabled}
        />
      );
    case 2:
      return (
        <EnglishParticulars
          Que={englishQ3}
          Ans={englishQ3ans}
          modeDisabled={modeFieldDisabled}
        />
      );
    case 3:
      return (
        <EnglishParticulars
          Que={englishQ4}
          Ans={englishQ4ans}
          modeDisabled={modeFieldDisabled}
        />
      );
    case 4:
      return (
        <EnglishParticulars
          Que={englishQ5}
          Ans={englishQ5ans}
          modeDisabled={modeFieldDisabled}
        />
      );
    default:
      return null;
  }
}

function SubjEnglish() {
  const [isProgressing, setIsProgressing] = useState(false);
  const [activeStep, setActiveStep] = useState(0);
  const { mode, englishId } = useParams();
  const {
    modeTitle,
    modeSubmit,
    modeFieldDisabled,
    modePermission,
    submitNew,
    response,
    dispatchError,
  } = useEnglishManager(mode, englishId);

  const navigate = useNavigate();
  const navigateAfter = (path, millisecond) => {
    return new Promise(() => {
      setTimeout(() => {
        navigate(path);
      }, millisecond);
    });
  };

  const steps = getSteps();
  const { formId } = form;
  //const currentValidation = modeValidation[activeStep];
  const isLastStep = activeStep === steps.length - 1;

  const handleBack = () => {
    try {
      setActiveStep(activeStep - 1);
    } catch (err) {
      dispatchError(err);
    }
  };

  const handleSubmit = async (values, actions) => {
    if (isLastStep) {
      try {
        setIsProgressing(true);
        switch (mode) {
          case "new":
            await submitNew(values);
            await navigateAfter("/subjenglish/records", 3000);
            break;
          default:
            actions.setSubmitting(false);
            break;
        }
      } catch (err) {
        dispatchError(err);
        actions.setSubmitting(false);
      } finally {
        actions.setSubmitting(false);
        setIsProgressing(false);
      }
    } else {
      try {
        setActiveStep(activeStep + 1);
        actions.setTouched({});
        actions.setSubmitting(false);
      } catch (err) {
        dispatchError(err);
      } finally {
        actions.setSubmitting(false);
        setIsProgressing(false);
      }
    }
  };

  return (
    <DashboardLayout>
      <Topnav title={modeTitle} />
      <AllowedTo perform={modePermission} no={() => <PermissionDenied />} />
      <MDBox pt={6} pb={3}>
        {response.isPending && <SubmissionProgress />}
        {isProgressing && <SubmissionProgress />}
        {response.error && (
          <>
            {response.error?.name === "OperationInvalidError" && (
              <InvalidOperation />
            )}
            {response.error?.name === "OperationIncompleteError" && (
              <IncompleteOperation />
            )}
            <ErrorSnackbar
              title={modeTitle}
              dateTime=""
              message={response.error?.message}
              autoDismiss
            />
          </>
        )}
        {response.success && (
          <SuccessSnackbar
            title={modeTitle}
            dateTime={""}
            message={response.success}
            autoDismiss
          />
        )}
        <MDBox>
          {response.error?.name !== "OperationInvalidError" && (
            <Grid
              container
              justifyContent="center"
              alignItems="center"
              sx={{ height: "100%" }}
            >
              <Grid item xs={12} lg={10}>
                <EnglishQue Que={englishPassage} />
              </Grid>
            </Grid>
          )}
        </MDBox>
        <MDBox mt={8}>
          {response.error?.name !== "OperationInvalidError" && (
            <Grid
              container
              justifyContent="center"
              alignItems="center"
              sx={{ height: "100%" }}
            >
              <Grid item xs={12} lg={10}>
                <Formik
                  enableReinitialize
                  initialValues={response.data}
                  //validationSchema={currentValidation}
                  onSubmit={handleSubmit}
                >
                  {(formik) => (
                    <Form id={formId} autoComplete="off">
                      <Card sx={{ height: "100%" }}>
                        <MDBox mx={2} mt={-3}>
                          <Stepper activeStep={activeStep} alternativeLabel>
                            {steps.map((label) => (
                              <Step key={label}>
                                <StepLabel>{label}</StepLabel>
                              </Step>
                            ))}
                          </Stepper>
                        </MDBox>
                        <MDBox p={3}>
                          <MDBox>
                            {getStepContent(activeStep, modeFieldDisabled)}
                            <MDBox
                              mt={4}
                              width="100%"
                              display="flex"
                              justifyContent="space-between"
                            >
                              {activeStep === 0 ? (
                                <MDBox />
                              ) : (
                                <MDButton
                                  variant="gradient"
                                  color="light"
                                  onClick={handleBack}
                                >
                                  back
                                </MDButton>
                              )}
                              <MDButton
                                disabled={formik.isSubmitting}
                                type="submit"
                                variant="gradient"
                                color="dark"
                              >
                                {isLastStep ? modeSubmit : "next"}
                              </MDButton>
                            </MDBox>
                          </MDBox>
                        </MDBox>
                      </Card>
                    </Form>
                  )}
                </Formik>
              </Grid>
            </Grid>
          )}
        </MDBox>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default SubjEnglish;

import form, {
  contactPersonForm,
  deliverySiteForm,
  billingContactForm,
} from "pages/subjmath/manage/schemas/form";

const {
  formField: {
    customerName,
    customerUEN,
    customerType,
    customerStatus,
    customerAddress,
    customerBuildingName,
    customerPostalCode,
    customerLandlineNumber,
    customerMobileNumber,
    customerEmail,
    customerWebsite,
    customerPreferredContact,
    contactPersons,
    deliverySites,
    billingContacts,
  },
} = form;

const initialValues = {
  [customerName.name]: "",
  [customerUEN.name]: "",
  [customerType.name]: null,
  [customerStatus.name]: null,
  [customerAddress.name]: "",
  [customerBuildingName.name]: "",
  [customerPostalCode.name]: "",
  [customerLandlineNumber.name]: "",
  [customerMobileNumber.name]: "",
  [customerEmail.name]: "",
  [customerWebsite.name]: "",
  [customerPreferredContact.name]: null,
  [contactPersons.name]: [],
  [deliverySites.name]: [],
  [billingContacts.name]: [],
};

const {
  formField: {
    contactPersonName,
    contactPersonEmail,
    contactPersonLandlineNumber,
    contactPersonMobileNumber,
  },
} = contactPersonForm;

const contactPersonsInitialValues = {
  [contactPersonName.name]: "",
  [contactPersonEmail.name]: "",
  [contactPersonLandlineNumber.name]: "",
  [contactPersonMobileNumber.name]: "",
};

const {
  formField: {
    deliverySiteName,
    siteSupervisorName,
    deliverySiteAddress,
    deliverySiteBlkBuildingName,
    deliverySitePostalCode,
    deliverySiteEmail,
    deliverySiteLandlineNumber,
    deliverySiteMobileNumber,
  },
} = deliverySiteForm;

const deliverySitesInitialValues = {
  [deliverySiteName.name]: "",
  [siteSupervisorName.name]: "",
  [deliverySiteAddress.name]: "",
  [deliverySiteBlkBuildingName.name]: "",
  [deliverySitePostalCode.name]: "",
  [deliverySiteEmail.name]: "",
  [deliverySiteLandlineNumber.name]: "",
  [deliverySiteMobileNumber.name]: "",
};

const {
  formField: {
    billingContactName,
    billingContactEmail,
    billingContactAddress,
    billingContactBlkBuildingName,
    billingContactPostalCode,
    billingContactLandlineNumber,
    billingContactMobileNumber,
  },
} = billingContactForm;

const billingContactsInitialValues = {
  [billingContactName.name]: "",
  [billingContactAddress.name]: "",
  [billingContactBlkBuildingName.name]: "",
  [billingContactPostalCode.name]: "",
  [billingContactEmail.name]: "",
  [billingContactLandlineNumber.name]: "",
  [billingContactMobileNumber.name]: "",
};

export {
  initialValues as default,
  contactPersonsInitialValues,
  deliverySitesInitialValues,
  billingContactsInitialValues,
};

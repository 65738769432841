import { useParams } from "react-router-dom";
import SubjMath from "pages/subjmath/manage/SubjMath";

// using Proxy Page to remount when route to same route location but different param
function SubjMathProxy() {
  const { mode, subjmathId } = useParams();
  return <SubjMath key={`${mode}/${subjmathId}`} />;
}

export default SubjMathProxy;

import * as Yup from "yup";
import form, {
  contactPersonForm,
  deliverySiteForm,
  billingContactForm,
} from "pages/subjmath/manage/schemas/form";

const {
  formField: {
    customerName,
    customerUEN,
    customerType,
    customerStatus,
    customerAddress,
    customerBuildingName,
    customerPostalCode,
    customerLandlineNumber,
    customerMobileNumber,
    customerWebsite,
    customerPreferredContact,
    contactPersons,
    deliverySites,
    billingContacts,
  },
} = form;

const noValidation = [
  Yup.object().shape({}),
  Yup.object().shape({}),
  Yup.object().shape({}),
  Yup.object().shape({}),
];

const validations = [
  Yup.object().shape({
    [customerName.name]: Yup.string()
      .required(customerName.errorMsg)
      .max(200, customerName.invalidMsg),
    [customerUEN.name]: Yup.string().max(200, customerUEN.invalidMsg),
    [customerType.name]: Yup.string()
      .required(customerType.errorMsg)
      .max(200, customerType.invalidMsg)
      .nullable(true),
    [customerStatus.name]: Yup.string()
      .required(customerStatus.errorMsg)
      .max(200, customerStatus.invalidMsg)
      .nullable(true),
    [customerAddress.name]: Yup.string()
      .required(customerAddress.errorMsg)
      .max(200, customerAddress.invalidMsg),
    [customerBuildingName.name]: Yup.string().max(
      200,
      customerBuildingName.invalidMsg
    ),
    [customerPostalCode.name]: Yup.string()
      .required(customerPostalCode.errorMsg)
      .matches("^\\d{6}$", customerPostalCode.invalidMsg),
    [customerLandlineNumber.name]: Yup.string()
      // .when(customerMobileNumber.name, {
      //   is: (customerMobileNumberValue) => !customerMobileNumberValue?.length,
      //   then: Yup.string().required(customerLandlineNumber.errorMsg),
      // })
      .matches(
        "^[+]?([0-9]*[\\-\\s.])*([0-9]*[(]+[0-9]{1,3}[)])*([0-9]*[\\-\\s.])*[0-9]{4,15}$",
        customerLandlineNumber.invalidMsg
      ),
    [customerMobileNumber.name]: Yup.string().matches(
      "^[+]?([0-9]*[\\-\\s.])*([0-9]*[(]+[0-9]{1,3}[)])*([0-9]*[\\-\\s.])*[0-9]{4,15}$",
      customerMobileNumber.invalidMsg
    ),
    [customerWebsite.name]: Yup.string().max(200, customerWebsite.invalidMsg),
    [customerPreferredContact.name]: Yup.string()
      .optional()
      //.required(customerPreferredContact.errorMsg)
      .max(200, customerPreferredContact.invalidMsg)
      .nullable(true),
  }),
  Yup.object().shape({
    [contactPersons.name]: Yup.array()
      .min(1, contactPersons.min1Msg)
      .max(100, contactPersons.max100Msg),
  }),
  Yup.object().shape({
    [deliverySites.name]: Yup.array()
      .min(1, deliverySites.min1Msg)
      .max(100, deliverySites.max100Msg),
  }),
  Yup.object().shape({
    [billingContacts.name]: Yup.array()
      .min(1, billingContacts.min1Msg)
      .max(100, billingContacts.max100Msg),
  }),
];

const {
  formField: {
    contactPersonName,
    contactPersonEmail,
    contactPersonLandlineNumber,
    contactPersonMobileNumber,
  },
} = contactPersonForm;

const contactPersonsValidation = Yup.object().shape({
  [contactPersonName.name]: Yup.string()
    .required(contactPersonName.errorMsg)
    .max(200, contactPersonName.invalidMsg),
  [contactPersonEmail.name]: Yup.string()
    //.required(contactPersonEmail.errorMsg)
    .max(200, contactPersonEmail.invalidMsg)
    .email(contactPersonEmail.invalidMsg),
  [contactPersonLandlineNumber.name]: Yup.string()
    // .when(contactPersonMobileNumber.name, {
    //   is: (contactPersonMobileNumberValue) =>
    //     !contactPersonMobileNumberValue?.length,
    //   then: Yup.string().required(contactPersonLandlineNumber.errorMsg),
    // })
    .matches(
      "^[+]?([0-9]*[\\-\\s.])*([0-9]*[(]+[0-9]{1,3}[)])*([0-9]*[\\-\\s.])*[0-9]{4,15}$",
      contactPersonLandlineNumber.invalidMsg
    ),
  [contactPersonMobileNumber.name]: Yup.string().matches(
    "^[+]?([0-9]*[\\-\\s.])*([0-9]*[(]+[0-9]{1,3}[)])*([0-9]*[\\-\\s.])*[0-9]{4,15}$",
    contactPersonMobileNumber.invalidMsg
  ),
});

const {
  formField: {
    deliverySiteName,
    siteSupervisorName,
    deliverySiteEmail,
    deliverySiteAddress,
    deliverySiteBlkBuildingName,
    deliverySitePostalCode,
    deliverySiteLandlineNumber,
    deliverySiteMobileNumber,
  },
} = deliverySiteForm;

const deliverySitesValidation = Yup.object().shape({
  [deliverySiteName.name]: Yup.string()
    .required(deliverySiteName.errorMsg)
    .max(200, deliverySiteName.invalidMsg),
  [siteSupervisorName.name]: Yup.string()
    //.required(siteSupervisorName.errorMsg)
    .max(200, siteSupervisorName.invalidMsg),
  [deliverySiteEmail.name]: Yup.string()
    //.required(deliverySiteEmail.errorMsg)
    .max(200, deliverySiteEmail.invalidMsg)
    .email(deliverySiteEmail.invalidMsg),
  [deliverySiteAddress.name]: Yup.string()
    .required(deliverySiteAddress.errorMsg)
    .max(200, deliverySiteAddress.invalidMsg),
  [deliverySiteBlkBuildingName.name]: Yup.string()
    //.required(deliverySiteBlkBuildingName.errorMsg)
    .max(200, deliverySiteBlkBuildingName.invalidMsg),
  [deliverySitePostalCode.name]: Yup.string()
    .required(deliverySitePostalCode.errorMsg)
    .matches("^\\d{6}$", deliverySitePostalCode.invalidMsg),
  [deliverySiteLandlineNumber.name]: Yup.string()
    .optional()
    .matches(
      "^[+]?([0-9]*[\\-\\s.])*([0-9]*[(]+[0-9]{1,3}[)])*([0-9]*[\\-\\s.])*[0-9]{4,15}$",
      deliverySiteLandlineNumber.invalidMsg
    ),
  [deliverySiteMobileNumber.name]: Yup.string()
    // .when(deliverySiteLandlineNumber.name, {
    //   is: (deliverySiteLandlineNumberValue) =>
    //     !deliverySiteLandlineNumberValue?.length,
    //   then: Yup.string().required(deliverySiteMobileNumber.errorMsg),
    // })
    .optional()
    .matches(
      "^[+]?([0-9]*[\\-\\s.])*([0-9]*[(]+[0-9]{1,3}[)])*([0-9]*[\\-\\s.])*[0-9]{4,15}$",
      deliverySiteMobileNumber.invalidMsg
    ),
});

const {
  formField: {
    billingContactName,
    billingContactEmail,
    billingContactAddress,
    billingContactBlkBuildingName,
    billingContactPostalCode,
    billingContactLandlineNumber,
    billingContactMobileNumber,
  },
} = billingContactForm;

const billingContactsValidation = Yup.object().shape({
  [billingContactName.name]: Yup.string()
    .required(billingContactName.errorMsg)
    .max(200, billingContactName.invalidMsg),
  [billingContactEmail.name]: Yup.string()
    // .required(billingContactEmail.errorMsg)
    .email(billingContactEmail.invalidMsg)
    .max(200, billingContactEmail.invalidMsg),
  [billingContactAddress.name]: Yup.string()
    //.required(billingContactAddress.errorMsg)
    .max(200, billingContactAddress.invalidMsg),
  [billingContactBlkBuildingName.name]: Yup.string()
    //.required(billingContactBlkBuildingName.errorMsg)
    .max(200, billingContactBlkBuildingName.invalidMsg),
  [billingContactPostalCode.name]: Yup.string()
    //.required(billingContactPostalCode.errorMsg)
    .matches("^\\d{6}$", billingContactPostalCode.invalidMsg),
  [billingContactLandlineNumber.name]: Yup.string()
    // .when(billingContactMobileNumber.name, {
    //   is: (billingContactMobileNumberValue) =>
    //     !billingContactMobileNumberValue?.length,
    //   then: Yup.string().required(billingContactLandlineNumber.errorMsg),
    // })
    .matches(
      "^[+]?([0-9]*[\\-\\s.])*([0-9]*[(]+[0-9]{1,3}[)])*([0-9]*[\\-\\s.])*[0-9]{4,15}$",
      billingContactLandlineNumber.invalidMsg
    ),
  [billingContactMobileNumber.name]: Yup.string().matches(
    "^[+]?([0-9]*[\\-\\s.])*([0-9]*[(]+[0-9]{1,3}[)])*([0-9]*[\\-\\s.])*[0-9]{4,15}$",
    billingContactMobileNumber.invalidMsg
  ),
});

export {
  validations as default,
  noValidation,
  contactPersonsValidation,
  deliverySitesValidation,
  billingContactsValidation,
};

import { useState } from "react";
import PropTypes from "prop-types";

import Card from "@mui/material/Card";

import MDBox from "components/atoms/MDBox";
import MDButton from "components/atoms/MDButton";
import MDTypography from "components/atoms/MDTypography";

//import BookingCard from "components/molecules/Cards/BookingCard";
//import SimpleBlogCard from "components/molecules/Cards/BlogCards/SimpleBlogCard";
//import ControllerCard from "components/molecules/Cards/ControllerCard";
//import Tooltip from "@mui/material/Tooltip";
//import Icon from "@mui/material/Icon";

function MathParticulars({ Que, Ans, QueNo }) {
  // Action buttons for the BookingCard
  const [showAns, setShowAns] = useState(false);

  const showAnsFunc = () => {
    showAns === false ? setShowAns(true) : setShowAns(false);
  };

  return (
    <MDBox>
      <MDBox lineHeight={0}>
        <MDTypography variant="h5">Question #{QueNo}</MDTypography>
      </MDBox>
      <MDBox mt={3}>
        <Card>
          <MDBox
            component="img"
            src={Que}
            alt={""}
            borderRadius="lg"
            shadow="md"
            width="100%"
            height="100%"
            position="relative"
            zIndex={1}
          />
        </Card>
        {showAns ? (
          <MDButton variant="gradient" color="error" onClick={showAnsFunc}>
            CLOSE
          </MDButton>
        ) : (
          <MDButton variant="gradient" color="info" onClick={showAnsFunc}>
            VIEW ANSWER
          </MDButton>
        )}
        {showAns && (
          <Card>
            <MDBox
              component="img"
              src={Ans}
              alt={""}
              borderRadius="lg"
              shadow="md"
              width="100%"
              height="100%"
              position="relative"
              zIndex={1}
            />
          </Card>
        )}
      </MDBox>
    </MDBox>
  );
}

MathParticulars.propTypes = {
  //mainForm: PropTypes.object.isRequired,
  //mainFormField: PropTypes.object.isRequired,
  //modeDisabled: PropTypes.bool,
  dispatchMainError: PropTypes.func,
};

export default MathParticulars;
